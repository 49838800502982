@tailwind base;
@tailwind components;
@tailwind utilities; 

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

@font-face {

    font-family: 'Figtree';
    src: url("../src/font/Figtree-Black.woff2") format('woff2');
    /* Add more formats for cross-browser compatibility if needed */
    font-weight: 900;
    font-style: normal; /* You can add 'italic' here for italic variations if available */
  
}
@layer base {
    li {
      @apply p-4
    }
    h1 {
      font-family: 'Figtree';
    src: url("../src/font/Figtree-Black.woff2") format('woff2');
    /* Add more formats for cross-browser compatibility if needed */
    font-weight: 900;
    font-style: normal; /* You can add 'italic' here for italic variations if available */
    }
   h2{
    font-family: 'Montserrat';
    font-weight: 700;
   }
    .morph {
    background: #dfe9fe !important;
    box-shadow: 4px 4px 8px #9b9b9b, -4px -4px 8px #ffffff !important;
    border-width: 2px;
    border-color: #c0c0c0;
    }

    .bordershadow {
      box-shadow: 4px 4px 8px #417be66b, -4px -4px 8px #ffffff81 !important;
      border-width: 1px;
      border-color: #c0c0c0;
      }
    
    
  }
  
  input[type="text"]:focus ~ .input-text,
  input[type="text"]:not(:placeholder-shown) ~ .input-text{
  @apply text-blue-500 transform -translate-y-10 -translate-x-2 scale-75;
  }

body{
 background-color: #fff;
}
@keyframes float {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -12px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.swiper-button-prev {
  color: transparent !important;
  width: 100px !important;
  top: 50% !important;;
  margin-top: -150px !important; 
}
.swiper-button-next {
  color: transparent !important;
  width: 100px !important;  
  margin-right:-2vh ;
  top: 50% !important;;
  margin-top: -150px !important; 
}



.parallax-container {
  background-image: url('../src/assets/pict/Hexaverse/debian2.jpg');
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  margin-top: 50px;
  width: 100%;
  height: 245px;
  overflow: hidden;
}

@media (min-width: 600px) {
  .parallax-container {
    margin-top: 0px;
    height: 400px;
  }
}

@media (min-width: 1024px) {
  .parallax-container {
    margin: 0;
    height: 100vh;
  }
}

.parallax-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  object-fit: cover;
  justify-content: center;
}

.parallax-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.parallax-element {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  object-fit: contain;
}